<template>
  <div class="main-container">
    <div class="main-content">
      <div class="section-schedule-detail">
        <div class="schedule-content-detail">
          <div class="schedule-detail-header">
            <h2>
              <span><i class="fal fa-alarm-clock"></i></span
              >{{ $t("scheduleDetail.title") }}
            </h2>
          </div>
          <v-form class="from-schedules">
            <v-row>
              <v-col cols="12" md="12" class="pt-10">
                <label class="label-input">{{
                  $t("scheduleDetail.name")
                }}</label>
                <v-text-field
                  dense
                  outlined
                  v-model="data.name"
                  :hint="`${server_errors.name}`"
                  persistent-hint
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" md="6">
                <label class="label-input"
                  >{{ $t("scheduleDetail.start_in")
                  }}<span class="text-show-error">{{
                    server_errors.start_in
                  }}</span></label
                >
                <date-picker
                  class="time--picker"
                  v-model="data.start_in"
                  style="width: 100%"
                  type="time"
                ></date-picker>
              </v-col>
              <v-col cols="6" md="6">
                <label class="label-input"
                  >{{ $t("scheduleDetail.checkin")
                  }}<span class="text-show-error">{{
                    server_errors.check_in_before
                  }}</span></label
                >
                <date-picker
                  class="time--picker"
                  v-model="data.check_in_before"
                  style="width: 100%"
                  type="time"
                ></date-picker>
              </v-col>
              <v-col cols="6" md="6" class="mt-8">
                <label class="label-input"
                  >{{ $t("scheduleDetail.start_out")
                  }}<span class="text-show-error">{{
                    server_errors.start_out
                  }}</span></label
                >
                <date-picker
                  class="time--picker"
                  v-model="data.start_out"
                  style="width: 100%"
                  type="time"
                ></date-picker>
              </v-col>
              <v-col cols="6" md="6" class="mt-8">
                <label class="label-input"
                  >{{ $t("scheduleDetail.checkout")
                  }}<span class="text-show-error">{{
                    server_errors.check_out_after
                  }}</span></label
                >
                <date-picker
                  class="time--picker"
                  v-model="data.check_out_after"
                  style="width: 100%"
                  type="time"
                ></date-picker>
              </v-col>
              <v-col class="mt-5" cols="6" md="6">
                <label class="label-input">{{
                  $t("scheduleDetail.late")
                }}</label>
                <v-text-field
                  type="number"
                  class="input-number"
                  outlined
                  dense
                  v-model="data.late_after"
                  :hint="`${server_errors.late_after}`"
                  persistent-hint
                >
                </v-text-field>
              </v-col>

              <v-col class="mt-5" cols="6" md="6">
                <label class="label-input">{{
                  $t("scheduleDetail.absence")
                }}</label>
                <v-text-field
                  type="number"
                  class="input-number"
                  outlined
                  dense
                  v-model="data.absense_after"
                  :hint="`${server_errors.absense_after}`"
                  persistent-hint
                >
                </v-text-field>
              </v-col>

              <v-col cols="12">
                <v-checkbox
                  class="label-input"
                  label="ຕັ້ງເວລາພັກ"
                  v-model="check"
                >
                </v-checkbox>
              </v-col>

              <v-col cols="12" v-if="check">
                <label class="label-input">{{
                  $t("scheduleDetail.break_time")
                }}</label>
                <v-select
                  class="center align-center"
                  :label="$t('scheduleDetail.label_select')"
                  dense
                  outlined
                  v-model="data.period_break_with_schedule_detail_id"
                  required
                  :items="listBreakDetail"
                  :item-text="getFieldText"
                  item-value="id"
                >
                  <!-- @change="addApprove('hr_approve_ot')" -->
                </v-select>
              </v-col>

              <v-col cols="6" class="mb-10">
                <v-btn
                  class="btn-save-change"
                  :loading="btnLoading"
                  @click="saveChange"
                >
                  {{ $t("scheduleDetail.save") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";

export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      check: false,
      break_type: "",
      listBreakDetail: [],
      btnLoading: false,
      data: {
        name: "",
        start_in: "",
        check_in_before: "",
        start_out: "",
        check_out_after: "",
        late_after: "",
        absense_after: "",
        period_break_with_schedule_detail_id: "",
      },
      server_errors: {
        name: "",
        start_in: "",
        check_in_before: "",
        start_out: "",
        check_out_after: "",
        late_after: "",
        absense_after: "",
        period_break_with_schedule_detail_id: "",
      },
    };
  },
  methods: {
    getFieldText(item) {
      return `${item.name} (${item.check_in_before} - ${item.check_out_after})`;
    },
    saveChange() {
      this.btnLoading = true;
      const checkInSchedule = this.$route.params.schedule_id;
      const item = {
        schedule_id: checkInSchedule,
        period_break_with_schedule_detail_id: this.check
          ? this.data.period_break_with_schedule_detail_id
          : null,
        name: this.data.name,
        start_in: moment(this.data.start_in).format("HH:mm:ss"),
        check_in_before: moment(this.data.check_in_before).format("HH:mm:ss"),
        start_out: moment(this.data.start_out).format("HH:mm:ss"),
        check_out_after: moment(this.data.check_out_after).format("HH:mm:ss"),
        late_after: this.data.late_after,
        absense_after: this.data.absense_after,
      };
      this.$axios
        .post(`company/check-in-schedule/${checkInSchedule}/detail`, item)
        .then((res) => {
          if (res.data.code === 200) {
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
            this.$router.push({ name: "scheduleDetail.index" });
            this.btnLoading = false;
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            const obj = error.response.data.errors;
            for (let [key, value] of Object.entries(obj)) {
              this.server_errors[key] = value[0];
            }
          }
          this.btnLoading = false;
        });
    },
    fetchScheduleDetail() {
      const id = this.$route.params.schedule_id;
      this.isLoading = true;
      this.$axios.get(`company/check-in-schedule/${id}/break`).then((res) => {
        if (res.data.code === 200) {
          this.isLoading = false;
          this.listBreakDetail = res.data.data;
        }
      });
    },
  },
  created() {
    this.fetchScheduleDetail();
  },
};
</script>

<style scoped lang="scss">
.section-schedule-detail {
  width: 100%;
  height: auto;
  // background-color: red;
  display: flex;
  flex-direction: column;
  align-items: center;

  .schedule-content-detail {
    margin: 0;
    border: 0;
    width: 600px;
    height: 800px;
    //  background-color: yellow;
    padding: 20px;
    position: relative;

    .from-schedules {
      width: 100%;
      margin-top: 40px;
    }
  }

  .schedule-detail-header {
    width: 100%;
    height: auto;

    h2 {
      display: flex;
      justify-content: center;
      align-self: center;
      font-family: $font-family;
      position: absolute;

      span {
        margin-right: 10px;
      }
    }
  }
}

.text-show-error {
  font-size: 12px;
  color: red;
}
</style>
